const state = {
  vacancies: [
    {
      id: "5bedf2f50c1b40275b1f6928",
      link: "senior-php",
      relocation: true,
      fulltime: true,
      salary: {
        from: "",
        to: "",
      },
      currency: "RUB",
      title: "Senior PHP developer",
      description:
        "Мы ищем в команду сильного back-end разработчика, готового участвовать в развитии наших многочисленных проектов, делиться своими знаниями, учиться и развиваться.\n",
      todolist:
        "Участие в разработке нового функционала проектов компании\nПоддержка, модернизация и оптимизация существующего функционала\nВзаимодействие с командой, участие в обсуждении задач и внутренних совещаниях\nУчастие в code-review\n",
      requirements:
        "Опыт работы с языком программирования PHP 5.6+ от 3 лет (PHP 7.1+ от 1 года), понимание различий между версиями языка\nУмение понимать и дорабатывать чужой код, находить и устранять недостатки в логике и архитектуре\nОтличные знания методологии ООП, умение применить эти знания на практике\nОпыт работы с фреймворком Laravel 5.6, участие в проектах, написанных на этом фреймворке\nОпыт работы с системой контроля версий (Git) и системой багтрекинга (Jira)\nОпыт работы с базами данных: MySQL (ORM и нативный SQL)\nУмение настраивать рабочее окружение на nginx + php-fpm + mysql\nОпыт работы с REST/RPC API",
      conditions:
        "Работа в большой и дружной команде\nИнтересные проекты и задачи\nОфициальное трудоустройство\nКонкурентная индексируемая заработная плата по результатам собеседования\nКикер, общение, напитки и печеньки прилагаются\n",
      skills: ["PHP 5.6+", "PHP 7.1", "Laravel 5.6", "GraphQL", "RabbitMQ"],
      pluses:
        "Опыт корпоративной разработки\nОпыт работы с PostgreSQL\nОпыт работы с фреймворками Yii, Symfony и т.п.\nЗнание JavaScript и других языков программирования\nОпыт взаимодействия с MongoDB, Redis, Memcached\nПонимание технологий: GraphQL, RabbitMQ\nЗнание и применение на практике принципов SOLID, DRY и KISS\nУмение писать Unit-тесты\n",
    },
    {
      id: "5bedf3710c1b40275b1f6929",
      link: "middle-php",
      relocation: true,
      fulltime: true,
      salary: {
        from: "",
        to: "",
      },
      currency: "RUB",
      title: "Middle PHP developer",
      description:
        "Мы ищем в нашу команду перспективного back-end разработчика, готового участвовать в развитии наших многочисленных проектов, делиться своими знаниями, учиться и развиваться самому.\n",
      todolist:
        "Участие в разработке нового функционала проектов компании\nПоддержка и модернизация существующего функционала\nПрофессиональное развитие с перспективой стать полноценным членом основной команды разработчиков\n",
      requirements:
        "Опыт работы с языком программирования PHP 5.6+\nУмение понимать и дорабатывать чужой код\nПонимание методологии ООП\nОпыт работы с базами данных: MySQL",
      conditions:
        "Работа в команде разработчиков компании\nИнтересные проекты и задачи\nОфициальное трудоустройство\nДля студентов есть вариант удаленной работы с присутствием в офисе от 2 раз в неделю\nКикер, общение, напитки и печеньки прилагаются\n",
      skills: ["PHP 5.6+", "MySQL", "Laravel"],
      pluses:
        "Опыт работы с PHP 7.1+\nОпыт работы с одним из фреймворков: Laravel, Yii, Symfony\nУмение настраивать рабочее окружение на nginx+php-fpm+mysql\nОпыт работы с REST/RPC API\nОпыт работы с системой контроля версий (Git) и системой багтрекинга (Jira)\nЗнание JavaScript и других языков программирования\nОпыт взаимодействия с MongoDB, Redis, Memcached\nПонимание технологий: Composer, GraphQL, RabbitMQ\nЗнание принципов SOLID, DRY, KISS\nУмение писать Unit-тесты",
    },
    {
      id: "5bedf3910c1b40275b1f692a",
      link: "senior-frontend",
      relocation: true,
      fulltime: true,
      salary: {
        from: "",
        to: "",
      },
      currency: "RUB",
      title: "Senior Frontend-разработчик",
      description:
        "Мы ищем в нашу большую и дружную команду надежного, сильного frontend - разработчика, готового участвовать в развитии наших многочисленных проектов, делиться своими знаниями, учиться и развиваться с",
      todolist:
        "Разработка новой функциональности интерфейсов веб-проектов компании\nПоддержка и улучшение существующего кода\nУчастие в разработке внутренних стандартов и требований к коду\nТесное взаимодействие с командой\n",
      requirements:
        "Уверенное знание стандарта ES6 (ES2015) и опыт работы с ним\nОпыт работы с ReactJS, понимание принципа его работы\nЗнание современных стандартов HTML, CSS\nОпыт работы с препроцессорами стилей (Less, SASS, Stylus)\nЗнание особенностей современных версий браузеров\nНавыки кросс-браузерной и адаптивной верстки\nОпыт работы с системой сборки webpack\nУмение разбираться в чужом коде\nОпыт работы с системами контроля версий (предпочтительна Git)\n",
      conditions:
        "Работа в команде\nИнтересные проекты и задачи\nОфициальное трудоустройство\nКонкурентная заработная плата по результатам собеседования\nКикер, общение, напитки и печеньки прилагаются",
      skills: ["ReactJS", "ES6", "ES2015"],
      pluses:
        "Опыт работы с GraphQL API в приложениях на React (Relay или Apollo)\nОпыт написания мобильных приложений на React Native\nЗнание PHP\nЗнание NodeJS",
    },
    {
      id: "5bedf3b30c1b40275b1f692b",
      link: "senior_swift",
      relocation: true,
      fulltime: true,
      salary: {
        from: "",
        to: "",
      },
      currency: "RUB",
      title: "Senior iOS developer (Swift)",
      description:
        "Мы ищем в команду опытного разработчика, который поможет нам с разработкой приложений под мобильные устройства Apple.\n",
      todolist:
        "Разработка мобильных приложений компании на iOS и модулей для них\nУчастие в формировании спецификации и документации по продукту\nПостроение и поддержка архитектуры приложений\n",
      requirements:
        "Опыт разработки мобильных приложений на iOS от 5 лет. Из них на языке Swift — от 3 лет\nЗнание принципов ООП, структур данных и паттернов проектирования\nПонимание работы паттерна MVVM и его внутреннего устройства\nОпыт работы с CoreData и Realm, понимание деталей их работы\nЗнание RxSwift, понимание принципов его работы\nОпыт использования GCD\nПонимание протокола TCP/IP и уровней OSI\nУмение писать Unit-тесты\nПонимание работы распределенных систем контроля версий (Git)",
      conditions:
        "Работа в большой команде\nИнтересные проекты и задачи\nОфициальное трудоустройство\nКикер, общение, напитки и печеньки прилагаются",
      skills: ["iOS", "Swift", "RxSwift", "CoreData"],
      pluses: "Если понимаете работу ARC (управление памятью)",
    },
    {
      id: "5bedf3d30c1b40275b1f692c",
      link: "productowner",
      relocation: false,
      fulltime: true,
      salary: {
        from: "",
        to: "",
      },
      currency: "RUB",
      title: "Product Owner",
      description: "",
      todolist:
        "Сбор, формирование и структурирование требований от бизнес-заказчиков;\nФормализация и выстраивание бизнес-процессов;\nОпределение kpi и метрик digital-продуктов;\nОценка необходимости внедрения функциональных доработок;\nОписание бизнес-логик вводимых фич;\nВнедрение и приемка фич;\nСбор и структурирование статистических данных от других отделов;\nФормирование бэклога на основе собранных и структурированных данных;\nАнализ и оценка вводимого функционала в рамках вверенных продуктов.",
      requirements:
        "Знания в области продуктового анализа;\nСледование принципам data-driven dev;\nУмение распознать, описать и внедрить продуктовые метрики для роста kpi продукта;\nЧёткое формулирование бизнес-требований для описания необходимых функциональностей;\nЗнания в области интернет-маркетинга будут большим плюсом;\nУмение и желание писать качественные use cases и user story;\nПри принятии решений опираться на статистику и факты, полученные в результате анализа.",
      conditions:
        "Оформление по ТК РФ ;\nБольшие возможности для профессионального и карьерного роста;\nКорпоративные ивенты;\nБлагоустроенное рабочее место в комфортабельном офисе с зоной отдыха и кухней. \n",
      skills: [
        "Стратегическое планирование",
        "Оперирование продуктовыми метриками",
        "Data Driven Development",
      ],
      pluses: "",
    },
    {
      id: "5bedf3f60c1b40275b1f692d",
      link: "projectmanager",
      relocation: false,
      fulltime: true,
      salary: {
        from: "",
        to: "",
      },
      currency: "RUB",
      title: "Project manager (РМ)",
      description:
        "Мы ищем сильного и прокаченного специалиста для решения основных задач по проектам. ",
      todolist:
        "Ежедневная коммуникация с командой проекта, заказчиками и подрядчиками;\nПроведение встреч, презентаций проекта\nВедение проектов (разработка проектной документации, креативные концепции)\nОценка/согласование задач клиента, постановка и распределение внутри проектной команды, контроль реализации, прототипирование\nРабота с документами (договоры, счета, акты)\nРазвитие новых направлений компании\n",
      requirements:
        "Опыт работы от 3 лет в сфере IT/разработке\nОпыт управления ресурсами в IT-сфере (общение с заказчиками, постановка задач, работа с командой)\nПонимание коммерческой разработки web/mobile-проектов\nГотовность развиваться и работать с большими проектами\nХорошее чувство юмора\n",
      conditions:
        "Уютный офис с обустроенным местом для отдыха\nОформление по ТК РФ\nГибкое начало рабочего дня\nСвоевременная выплата ЗП и премий\nКарьерный рост\n",
      skills: ["Прототипирование", "Scrum", "Agile"],
      pluses: "",
    },
    {
      id: "5beefa304e7c580d0cf0102a",
      link: "frontend_dev",
      relocation: true,
      fulltime: true,
      salary: {
        from: "",
        to: "",
      },
      currency: "RUB",
      title: "Middle/Junior Frontend-разработчик",
      description: "",
      todolist:
        "Разработка новой функциональности интерфейсов веб-проектов компании\nПоддержка и улучшение существующего кода\nУчастие в разработке внутренних стандартов и требований к коду\nТесное взаимодействие с командой\n",
      requirements:
        "Уверенное знание стандарта ES6 (ES2015) и опыт работы с ним\nОпыт работы с ReactJS, понимание его принципа работы\nЗнание современных стандартов HTML, CSS\nОпыт работы с препроцессорами стилей (Less, SASS, Stylus)\nЗнание особенностей современных версий браузеров\nНавыки кросс-браузерной и адаптивной верстки\n",
      conditions:
        "Работа в команде равных с перспективой войти в основную команду разработчиков компании.\nИнтересные проекты и задачи.\nОфициальное трудоустройство.\nДля студентов есть возможность удаленной работы с присутствием в офисе от 2 раз в неделю.\nКикер, общение, напитки и печеньки прилагаются\n",
      pluses:
        "Опыт работы с системой сборки webpack\nУмение разбираться в чужом коде\nОпыт работы с системами контроля версий (предпочтительно, Git)\nОпыт работы с GraphQL API в приложениях на React (Relay или Apollo)\nОпыт написания мобильных приложений на React Native\nЗнание PHP\nЗнание NodeJS\n",
      skills: ["ES6", "ReactJS", "ES2015"],
    },
    {
      id: "5beefdd04e7c580d0cf0102b",
      link: "middle_swift",
      relocation: true,
      fulltime: true,
      salary: {
        from: "",
        to: "",
      },
      currency: "RUB",
      title: "Middle iOS developer (Swift)",
      description:
        "Мы ищем в нашу команду сильного опытного разработчика, который поможет нам с разработкой приложений под мобильные устройства Apple.",
      todolist:
        "Разработка мобильных приложений компании на iOS и модулей для них\nУчастие в формировании спецификации и документации по продукту\n",
      requirements:
        "Опыт разработки мобильных приложений на iOS от 3 лет. Из них на языке Swift — от 2 лет\nЗнание принципов ООП, структур данных и паттернов проектирования\nПонимание работы паттерна MVVM и его внутреннего устройства\nОпыт работы с CoreData и Realm и понимание деталей их работы\nУмение писать Unit-тесты\nПонимание работы распределенных систем контроля версий (Git)\n",
      conditions:
        "Работа в большой и дружной команде;\nИнтересные проекты и задачи;\nОфициальное трудоустройство;\nКикер, общение, напитки и печеньки прилагаются.",
      pluses:
        "Знаете RxSwift, и понимаете принципы его работы\nИмеете опыт использования GCD\nПонимаете работу ARC (управление памятью)\n",
      skills: ["Swift", "iOS", "Realm"],
    },
  ],
};
export default {
  state,
};
