<template>
  <div class="main">
    <main-menu />
    <router-view />
    <main-footer />
    <main-modal name="OrderThanks" :title="$t('form.recall')" />
    <main-modal name="FeedbackThanks" :title="$t('form.contact')" />
    <main-modal name="OrderError" :title="$t('form.error')" />
  </div>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import MainFooter from "@/components/MainFooter";
import MainModal from "@/components/MainModal";

export default {
  components: {
    MainModal,
    MainFooter,
    MainMenu,
  },
};
</script>

<style></style>
