import { render, staticRenderFns } from "./ClearLayout.vue?vue&type=template&id=857f95ae&scoped=true&"
import script from "./ClearLayout.vue?vue&type=script&lang=js&"
export * from "./ClearLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "857f95ae",
  null
  
)

export default component.exports