import Vue from "vue";
import VueRouter from "vue-router";
import ClearLayout from "@/layouts/ClearLayout";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    beforeEnter: (to, from, next) => {
      next({ name: "Home" });
    },
  },
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "HomeView" */ "../views/HomeView.vue"),
  },
  {
    path: "/about",
    component: ClearLayout,
    children: [
      {
        path: "",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "AboutView" */ "../views/AboutView.vue"),
      },
      {
        path: ":id",
        name: "AboutInfo",
        component: () =>
          import(
            /* webpackChunkName: "ServiceView" */ "../views/ServiceView.vue"
          ),
      },
    ],
  },
  {
    path: "/projects",
    component: ClearLayout,
    children: [
      {
        path: "",
        name: "Projects",
        component: () =>
          import(
            /* webpackChunkName: "ProjectsView" */ "../views/ProjectsView.vue"
          ),
      },
      {
        path: ":id",
        name: "Project",
        component: () =>
          import(
            /* webpackChunkName: "ProjectView" */ "../views/ProjectView.vue"
          ),
      },
    ],
  },
  {
    path: "/partners",
    name: "Partners",
    component: () =>
      import(
        /* webpackChunkName: "PartnersView" */ "../views/PartnersView.vue"
      ),
  },
  {
    path: "/vacancies",
    component: ClearLayout,
    children: [
      {
        path: "",
        name: "Vacancies",
        component: () =>
          import(
            /* webpackChunkName: "ServiceView" */ "../views/VacanciesView.vue"
          ),
      },
      {
        path: ":id",
        name: "Vacancy",
        component: () =>
          import(
            /* webpackChunkName: "VacancyView" */ "../views/VacancyView.vue"
          ),
      },
    ],
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () =>
      import(
        /* webpackChunkName: "ContactsView" */ "../views/ContactsView.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
