<template>
  <div :class="[`modal modal--${modalState}`]" v-if="modalState">
    <div class="modal-scroll">
      <div class="modal-content">
        <div class="modal-title" v-if="title.length">{{ title }}</div>
        <slot />
      </div>
    </div>
    <div class="modal-close" @click="close">
      <svg
        width="69"
        height="69"
        viewBox="0 0 69 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="69" height="69" fill="black" />
        <path
          d="M18 18C23.2 23.2 42.1667 42.1667 51 51"
          stroke="white"
          stroke-width="3"
        />
        <path
          d="M51 18C45.8 23.2 26.8333 42.1667 18 51"
          stroke="white"
          stroke-width="3"
        />
      </svg>
    </div>
    <div class="modal-overlay"></div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      required: false,
      default: null,
    },
    footer: {
      required: false,
      default: false,
    },
    opened: {
      required: false,
      default: false,
    },
    title: {
      required: false,
      default: "",
    },
  },
  data() {
    return {
      modalState: false,
    };
  },
  created() {
    if (this.opened) {
      this.open();
    }
    if (this.name && this.name !== null) {
      this.$root.$on("openModal", (modalName) => {
        if (modalName === this.name) {
          this.open();
        }
      });
      this.$root.$on("closeModal", (modalName) => {
        if (modalName === this.name) {
          this.close();
        }
      });
    }
  },
  methods: {
    open() {
      this.modalState = "opening";
      setTimeout(() => {
        this.modalState = "open";
      }, 50);
    },
    close() {
      this.modalState = "closing";
      setTimeout(() => {
        this.modalState = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  transform: translateY(50%) scale(0.5);
  opacity: 0;
  transition: all 0.3s;

  &--open {
    opacity: 1;
    transform: none;
  }

  &-scroll {
    max-width: 90vw;
    max-height: 90vh;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    z-index: 2;
    width: $content;
    @include mobile {
      max-width: 100vw;
      max-height: 100vh;
    }
  }

  &-content {
    display: block;
    background: #fff;
    width: 100%;
    @include mobile {
      padding: 0 1rem;
      box-sizing: border-box;
    }
  }

  &-title {
    font-family: "PT Mono";
    font-size: 2rem;
    line-height: 2.25rem;
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background: #fff;
  }

  &-close {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 5rem;
    height: 5rem;

    @include tablet {
      width: 4rem;
      height: 4rem;
    }

    @include mobile {
      width: 3rem;
      height: 3rem;
    }

    svg {
      width: 100%;
      height: 100%;

      &:hover {
        rect {
          fill: $gray;
        }
      }
    }
  }
}
</style>
