<template>
  <footer>
    <div class="container footer">
      <div class="content">
        <div class="footer-col">
          <router-link to="/">
            <Logo color="white" />
          </router-link>
          <a :href="`mailto:hello@itpark.spb.ru`" class="footer-info"
            >hello@itpark.spb.ru</a
          >
        </div>
        <div class="footer-col">
          <router-link
            v-for="(link, k) in menu"
            :to="link.link"
            :key="`col1-link-${k}`"
            class="footer-link"
            data-underline-root="footer"
            data-underlinestyle="dark"
          >
            <span>{{ $t(link.text) }}</span>
          </router-link>
        </div>
        <div class="footer-col">
          <router-link
            v-for="(link, k) in menu2"
            :to="link.link"
            :key="`col2-link-${k}`"
            class="footer-link"
            data-underline-root="footer"
            data-underlinestyle="dark"
          >
            <span>{{ $t(link.text) }}</span>
          </router-link>
          <div class="footer-link">
            <p>ООО «ИТ-Парк»</p>
            <p>{{ $t("info.address") }}</p>
            <p>ИНН: 7842132882</p>
            <p>ОГРН: 1177847141779</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from "./MainLogo";

export default {
  components: {
    Logo,
  },
  data() {
    return {
      menu: [
        {
          link: "/about",
          text: "menu.about",
        },
        {
          link: "/projects",
          text: "menu.projects",
        },
        {
          link: "/partners",
          text: "menu.partners",
        },
        {
          link: "/vacancies",
          text: "menu.vacancies",
        },
        {
          link: "/contacts",
          text: "menu.contacts",
        },
      ],
      menu2: [],
    };
  },
};
</script>

<style scoped lang="scss">
footer {
  color: #fff;
  padding: 4rem 0;
  width: 100%;
  background-color: $backgroundGray;
  z-index: 2;

  @include mobile {
    padding: 2rem 0;
  }
}

.footer {
  .content {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 31%;

    svg {
      max-height: 2rem;
      width: unset;
    }

    &:last-child {
      width: 36%;
    }

    &:nth-child(1) {
      @include tabletOnly {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3rem;
      }

      @include mobile {
        width: 100%;
        align-items: center;
      }
    }

    &:nth-child(2) {
      @include tablet {
        width: 40%;
      }
    }

    &:nth-child(3) {
      @include tablet {
        width: 60%;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      @include mobile {
        margin-top: 2rem;
        width: 100%;
        align-items: center;
        text-align: center;
      }
    }
  }

  &-info {
    margin-top: 1.5rem;
    font-family: "Roboto Mono";
    font-style: normal;
    line-height: 2rem;
    font-size: 1.5rem;
    color: #fff;
    text-decoration: none;
    font-weight: 300;

    @include tablet {
      line-height: 1.7rem;
      font-size: 1.2rem;
      margin-top: 0;
    }
  }

  &-link {
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
    color: #ffffff;
    margin-bottom: 1rem;
    font-family: "Roboto Mono";
    font-size: 1.1rem;
    > p {
      margin: 0;
    }
  }
}
</style>
