/* eslint-disable max-len */
const state = {
  phone: "+78005110187",
  phoneForDisplay: "8 (800) 511-01-87",
  email: "hello@itpark.spb.ru",
  decoratedEmail: "<span>hello@itpark.spb.ru</span>",
  decoratedPhone: "<span>8 (800)</span> 511 01 87",
};

const getters = {
  getCompanyInfo: (data) => {
    const { phone, phoneForDisplay, email, decoratedEmail, decoratedPhone } =
      data;
    return {
      phone,
      phoneForDisplay,
      email,
      decoratedEmail,
      decoratedPhone,
    };
  },
};

export default {
  state,
  getters,
};
