import Vue from "vue";
import Vuex from "vuex";

import info from "@/store/info";
import translate from "@/store/translate";
import vacancies from "@/store/vacancies";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    info,
    translate,
    vacancies,
  },
  strict: debug,
});
