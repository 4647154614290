<template>
  <svg
    width="259"
    height="58"
    viewBox="0 0 259 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="[color]"
      d="M-2.9749e-06 44.7706V40.5155H11.5028V17.3902H-2.9749e-06V13.1352H16.7668V40.5155H28.0747V44.7706H-2.9749e-06ZM10.1381 4.25506C10.1381 3.14504 10.528 2.22003 11.3079 1.29501C12.0877 0.555005 13.0625 -2.82291e-06 14.4273 -2.82291e-06C15.792 -2.82291e-06 16.7668 0.370003 17.7416 1.29501C18.5215 2.03502 19.1064 3.14504 19.1064 4.25506C19.1064 5.36507 18.7165 6.29008 17.7416 7.03009C16.9618 7.7701 15.792 8.1401 14.4273 8.1401C13.2575 8.1401 12.0877 7.7701 11.3079 7.03009C10.528 6.29008 10.1381 5.36507 10.1381 4.25506Z"
      fill="white"
    />
    <path
      :class="[color]"
      d="M27.4902 13.1351H35.4837V6.84506L40.7477 5.55005V13.1351H58.2944V17.3902H40.5528V32.1904C40.5528 35.3354 41.3326 37.5554 42.8923 39.0355C44.452 40.5155 46.7916 41.2555 49.5211 41.2555C51.4707 41.2555 53.2254 40.8855 54.7851 40.1455C56.3448 39.4055 57.5146 38.6655 58.8793 37.7404L60.634 41.4405C59.0743 42.5505 57.3196 43.4755 55.175 44.2155C53.0304 44.9555 50.8858 45.3255 48.5463 45.3255C46.7916 45.3255 45.0369 45.1405 43.4772 44.5855C41.9175 44.0305 40.5528 43.2905 39.383 42.3655C38.2132 41.4405 37.2384 40.1455 36.4585 38.4805C35.6787 36.8154 35.4837 34.9654 35.4837 32.9304V17.3902H27.4902V13.1351Z"
      fill="white"
    />
    <path
      d="M83.4449 -2.82291e-06H85.3945L101.576 25.9003H96.1175L84.2247 6.47508L71.7471 25.7153H66.2881L83.4449 -2.82291e-06Z"
      fill="#F15735"
    />
    <path
      :class="[color]"
      d="M112.883 24.2352C112.883 23.8652 112.883 23.3102 112.883 22.7552C112.883 22.2002 112.883 21.4602 112.689 20.9052C112.494 20.3502 112.494 19.6102 112.494 19.0552C112.494 18.5002 112.299 17.9451 112.299 17.3901H107.814V13.3201H116.588L117.173 17.7601H117.563C118.732 16.2801 120.292 14.9851 122.047 14.0601C123.801 13.1351 126.141 12.5801 128.87 12.5801C133.55 12.5801 137.254 13.8751 139.593 16.2801C141.933 18.6852 143.103 22.7552 143.103 28.4903C143.103 31.0803 142.713 33.4853 141.933 35.7054C141.153 37.7404 139.983 39.5904 138.424 41.0704C136.864 42.5505 135.109 43.6605 132.965 44.4005C130.82 45.1405 128.481 45.5105 125.946 45.5105C124.971 45.5105 124.191 45.5105 123.412 45.3255C122.632 45.3255 122.047 45.1405 121.462 45.1405C120.877 44.9555 120.292 44.9555 119.707 44.7705C119.122 44.5855 118.537 44.4005 117.758 44.2155V57.5356H112.883V24.2352ZM128.091 17.2051C126.726 17.2051 125.556 17.3901 124.386 17.9451C123.217 18.3151 122.242 19.0552 121.267 19.7952C120.292 20.5352 119.707 21.4602 119.122 22.3852C118.537 23.3102 118.147 24.2352 118.147 25.1602V39.4054C119.122 39.9604 120.292 40.5154 121.462 40.8854C122.632 41.2554 124.191 41.4404 126.336 41.4404C129.845 41.4404 132.77 40.3304 134.914 38.1104C137.059 35.8904 138.034 32.7453 138.034 28.4903C138.034 24.9752 137.254 22.2002 135.889 20.1652C134.134 18.1301 131.6 17.2051 128.091 17.2051Z"
      fill="white"
    />
    <path
      :class="[color]"
      d="M152.071 15.7253C154.021 14.6153 156.165 13.8753 158.505 13.5053C160.844 13.1353 163.184 12.7653 165.523 12.7653C167.863 12.7653 169.618 13.1353 170.982 13.6903C172.347 14.4303 173.517 15.1703 174.297 16.2803C175.077 17.3903 175.662 18.5003 176.051 19.9804C176.246 21.2754 176.441 22.7554 176.441 24.0504C176.441 25.7154 176.441 27.3804 176.246 29.2305C176.246 31.0805 176.051 32.9305 176.051 34.5955C176.051 36.6306 176.246 38.6656 176.441 40.5156H180.926V44.5856H172.152L171.567 39.7756H171.372C171.177 40.1456 170.787 40.7006 170.203 41.2556C169.618 41.8106 169.033 42.3656 168.058 42.9206C167.278 43.4756 166.108 44.0306 164.939 44.4006C163.769 44.7706 162.209 44.9557 160.454 44.9557C157.14 44.9557 154.606 44.2156 152.656 42.5506C150.706 40.8856 149.731 38.8506 149.731 36.0755C149.731 33.8555 150.316 32.1905 151.291 30.7105C152.266 29.2305 153.631 28.1205 155.58 27.3804C157.53 26.6404 159.675 26.2704 162.404 26.0854C165.134 25.9004 168.058 26.0854 171.372 26.6404C171.567 24.7904 171.567 23.1254 171.567 21.8304C171.372 20.5354 170.982 19.6103 170.593 18.6853C170.008 17.9453 169.228 17.3903 168.253 17.0203C167.278 16.6503 166.108 16.4653 164.549 16.4653C162.599 16.4653 160.649 16.6503 158.895 17.2053C157.14 17.7603 155.385 18.3153 154.021 18.8703L152.071 15.7253ZM162.014 41.0706C163.184 41.0706 164.354 40.8856 165.523 40.5156C166.498 40.1456 167.473 39.5906 168.253 39.0356C169.033 38.4806 169.618 37.7406 170.203 37.1856C170.787 36.4455 171.177 35.8905 171.372 35.1505V30.7105C169.033 30.3405 166.888 30.1555 164.939 30.1555C162.989 30.1555 161.234 30.3405 159.87 30.7105C158.505 31.0805 157.335 31.8205 156.555 32.5605C155.775 33.4855 155.385 34.4105 155.385 35.8905C155.385 37.1856 155.97 38.4806 156.945 39.5906C157.92 40.5156 159.675 41.0706 162.014 41.0706Z"
      fill="white"
    />
    <path
      :class="[color]"
      d="M187.165 44.6255V40.3705H196.133V17.2452H187.165V12.9901H199.643L200.618 17.0602H201.007C202.567 15.7652 204.127 14.6551 206.076 13.7301C208.026 12.8051 210.171 12.2501 212.705 12.2501C214.265 12.2501 215.435 12.4351 216.41 12.9901C217.384 13.5451 217.969 14.2851 218.554 15.3952C219.139 16.5052 219.334 17.8002 219.529 19.2802C219.724 20.7602 219.724 22.4253 219.724 24.2753H215.045C215.045 21.6852 214.85 19.6502 214.265 18.3552C213.68 17.0602 212.705 16.5052 211.146 16.5052C209.781 16.5052 208.611 16.6902 207.441 17.0602C206.271 17.4302 205.297 17.9852 204.517 18.3552C203.737 18.7252 202.957 19.4652 202.372 20.0202C201.787 20.5752 201.397 20.9452 201.202 21.3152V39.8155H213.875V44.0705H187.165V44.6255Z"
      fill="white"
    />
    <path
      :class="[color]"
      d="M223.428 0.555179H234.151V26.6405H237.855L250.918 13.1353H257.156L242.534 28.1205L254.622 40.5157H258.911V44.7707H252.087L237.855 30.5255H234.151V44.7707H229.082V4.81023H223.428V0.555179Z"
      fill="white"
    />
    <path
      :class="[color]"
      d="M61.4139 34.7808C61.2189 35.1508 60.829 35.7058 60.4391 36.0758C59.6592 37.1858 58.6844 38.1108 57.5146 38.8508V43.6609C59.4643 42.5508 61.219 41.2558 62.7787 39.7758C64.3384 38.1108 65.3132 36.4458 66.093 34.7808H61.4139Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      required: false,
      default: "black",
    },
  },
};
</script>

<style lang="scss" scoped>
svg path.white {
  fill: #fff;
}
svg path.black {
  fill: #000;
}
</style>
