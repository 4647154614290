<template>
  <div class="menu">
    <div class="container">
      <div class="content menu-elements">
        <div class="menu-element menu-element--logo">
          <router-link to="/">
            <MainLogo />
          </router-link>
        </div>
        <div
          :class="[
            'menu-element',
            'menu-element--links',
            { 'menu-element--links-opened': isOpenMenu },
          ]"
        >
          <router-link
            class="menu-link"
            v-for="(el, key) in menu"
            :key="`menu-${key}`"
            :to="el.link"
            data-underline-root="menu"
          >
            <span @click="closeMenu">{{ $t(el.text) }}</span>
          </router-link>
        </div>
        <div :class="['menu-element', 'langs', { 'langs--show': isOpenMenu }]">
          <div
            :class="{
              'langs-lang': true,
              'langs-lang--active': $i18n.locale === lang,
            }"
            v-for="(lang, k) in $i18n.availableLocales"
            :key="`lang-${k}`"
            @click="$i18n.locale = lang"
          >
            {{ lang }}
          </div>
        </div>
        <div
          :class="['menu-element', 'burger', { 'burger--active': isOpenMenu }]"
          @click="toggleMenu"
        >
          <div class="burger-box">
            <div class="burger-inner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLogo from "@/components/MainLogo";

export default {
  components: {
    MainLogo,
  },
  data() {
    return {
      menu: [
        {
          link: "/about",
          text: "menu.about",
        },
        {
          link: "/projects",
          text: "menu.projects",
        },
        {
          link: "/partners",
          text: "menu.partners",
        },
        {
          link: "/vacancies",
          text: "menu.vacancies",
        },
        {
          link: "/contacts",
          text: "menu.contacts",
        },
      ],
      isOpenMenu: false,
      scrollPos: 0,
    };
  },
  methods: {
    noScroll(e) {
      e.preventDefault();
      e.stopPropagation();
      window.scrollTo(0, this.scrollPos);
    },
    closeMenu() {
      document.body.classList.remove("no-scroll");
      window.removeEventListener("scroll", this.noScroll);
      this.isOpenMenu = false;
    },
    openMenu() {
      document.body.classList.add("no-scroll");
      window.addEventListener("scroll", this.noScroll);
      this.scrollPos = window.scrollY;
      this.isOpenMenu = true;
    },
    toggleMenu() {
      const isOpenMenu = !this.isOpenMenu;
      if (isOpenMenu) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #fff;
  z-index: 10;
  border-bottom: 1px solid rgba($gray, 0.2);

  @include tablet {
    height: 80px;
  }

  @include tabletLandscape {
    height: 60px;
  }

  @include mobile {
    height: 60px;
  }

  & + * {
    margin-top: 100px;

    @include tablet {
      margin-top: 80px;
    }
    @include tabletLandscape {
      margin-top: 60px;
    }

    @include mobile {
      margin-top: 60px;
    }
  }

  &-elements {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;

    @include tablet {
      height: 80px;
    }

    @include tabletLandscape {
      height: 60px;
    }

    @include mobile {
      height: 60px;
    }
  }

  &-element {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: "PT Mono";

    a {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      white-space: nowrap;

      svg {
        max-height: 2rem;
        width: unset;

        @include mobile {
          max-height: 1.8rem;
        }
      }
    }

    &--links {
      width: 100%;

      @include tablet {
        display: none;
        min-height: calc(100vmax - 80px);
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 23vh;
        position: fixed;
        top: 80px;
        bottom: auto;
        left: 0;
        right: 0;
        background: #fff;
        overflow: scroll;

        @include tabletLandscape {
          top: 60px;
          min-height: calc(100vmax - 60px);
        }

        @include mobile {
          top: 60px;
          min-height: calc(100vmax - 60px);
        }

        &-opened {
          display: flex;

          @include mobileLandscape {
            padding-top: 6vh;
          }
        }
      }
    }
  }

  &-link {
    color: $blue;
    font-size: 1.125rem;
    &:not(:last-child) {
      margin-right: 0.5rem;

      @include tablet {
        margin-right: 0;
      }
    }

    @include tablet {
      font-size: 1.25rem;
      margin-bottom: 40px;

      @include mobileLandscape {
        margin-bottom: 30px;
      }
    }
  }
}

.langs {
  @include tablet {
    margin-left: auto;
    display: none;

    &--show {
      display: flex;
      position: fixed;
      top: 20vh;
      left: 0;
      margin-left: calc(50% - 36px);

      @include mobileLandscape {
        top: 13px;
      }
    }
  }

  &-lang {
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    color: $blue;
    font-size: 18px;
    text-transform: uppercase;

    &--active {
      background: #000;
      color: #fff;
    }
  }
}

.burger {
  display: none;
  margin-left: 16px;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  cursor: pointer;

  @include tablet {
    display: block;
  }

  &-inner {
    position: absolute;
    top: 50%;
    margin-top: -2px;
    width: 43px;
    height: 3px;
    background-color: #000;
    transition-property: transform;
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    @include mobile {
      margin-top: -1px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 43px;
      height: 3px;
      background-color: #000;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &::before {
      top: -15px;
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;

      @include tabletLandscape {
        top: -14px;
      }

      @include mobile {
        top: -14px;
      }
    }

    &::after {
      bottom: -15px;
      transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);

      @include tabletLandscape {
        bottom: -14px;
      }

      @include mobile {
        bottom: -14px;
      }
    }
  }

  &--active .burger-inner {
    transition-delay: 0.12s;
    transform: rotate(45deg);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      top: 0;
      opacity: 0;
      transition: top 75ms ease, opacity 75ms ease 0.12s;
    }
    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 75ms ease,
        transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    }
  }

  &-box {
    width: 43px;
    height: 35px;
    position: relative;
  }
}
</style>
